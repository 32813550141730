@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bg-image {
    @apply bg-no-repeat bg-center bg-cover;
  }
}

html,
body,
#root {
  min-height: 100vh;
  overscroll-behavior: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
}

.gradient-mask {
  --center-opacity: 0.012;
  mask-image: radial-gradient(
    rgba(0, 0, 0, var(--center-opacity)),
    rgba(0, 0, 0, var(--center-opacity)),
    transparent,
    transparent
  );
}
